/* --------------------------------------------------

   Colors
   -------------------------------------------------- */
/* --------------------------------------------------

   Wrapper Widths
   -------------------------------------------------- */
/* --------------------------------------------------

   Gallery
   -------------------------------------------------- */
/* --------------------------------------------------

   Breakpoint Settings
   -------------------------------------------------- */
/* --------------------------------------------------

   Breakpoint Mixin
   -------------------------------------------------- */
/* --------------------------------------------------

   Gradients
   -------------------------------------------------- */
/* --------------------------------------------------

   Box Shadow
   -------------------------------------------------- */
/* --------------------------------------------------

   Flexbox SASS mixins
   The spec: http://www.w3.org/TR/css3-flexbox
   -------------------------------------------------- */
body p, body span, body a {
  font-size: 16px; }

.wrapper p, .wrapper--medium-width p, .wrapper--small-width p, .wrapper span, .wrapper--medium-width span, .wrapper--small-width span {
  color: #6B6B6B;
  line-height: 25px;
  font-weight: 300; }

.wrapper--color p, .wrapper--color span, .wrapper--color h1, .wrapper--color h2, .wrapper--color h3, .wrapper--color label, .wrapper--color a, .wrapper--color li, .wrapper--color-trans p, .wrapper--color-trans span, .wrapper--color-trans h1, .wrapper--color-trans h2, .wrapper--color-trans h3, .wrapper--color-trans label, .wrapper--color-trans a, .wrapper--color-trans li {
  color: #ffffff; }

.wrapper--color a, .wrapper--color-trans a {
  text-decoration: none; }

.two-col .col-right--entry p {
  text-transform: uppercase;
  font-weight: 500; }

.section-title {
  color: #2e2e2e;
  text-transform: uppercase;
  font-weight: 700;
  /* Mobile */
  /* Tablet */
  /* Desktop */ }
  @media (max-width: 480px) {
    .section-title {
      font-size: 16px; } }
  @media (min-width: 480px) {
    .section-title {
      font-size: 16px; } }
  @media (min-width: 1024px) {
    .section-title {
      font-size: 22px; } }
  @media (min-width: 1280px) {
    .section-title {
      font-size: 22px; } }
  .section-title--red {
    color: #ee2c3d; }
  .section-title--white {
    color: #ffffff; }

.title {
  font-family: "Playfair Display", serif;
  color: #ffffff;
  /* Mobile */
  /* Tablet */
  /* Desktop */ }
  @media (max-width: 480px) {
    .title {
      font-size: 35px; } }
  @media (min-width: 480px) {
    .title {
      font-size: 45px; } }
  @media (min-width: 1024px) {
    .title {
      font-size: 52px; } }
  @media (min-width: 1280px) {
    .title {
      font-size: 60px; } }
  .title .services-toggle {
    /* Mobile */
    /* Tablet */
    /* Desktop */ }
    @media (max-width: 480px) {
      .title .services-toggle {
        font-size: 35px; } }
    @media (min-width: 480px) {
      .title .services-toggle {
        font-size: 45px; } }
    @media (min-width: 1024px) {
      .title .services-toggle {
        font-size: 52px; } }
    @media (min-width: 1280px) {
      .title .services-toggle {
        font-size: 60px; } }

.services-item .services-item-details {
  font-size: 22px; }

.filter-button-group button {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff; }
  .filter-button-group button:hover {
    font-weight: 700; }

.btn, .btn-load {
  font-size: 14px;
  font-weight: 700;
  color: #ffffff; }

.form .form-item .form-label input, .form .form-item .form-label textarea {
  font-size: 18px;
  font-weight: 300; }

.form .form-item .form-label label {
  font-size: 18px; }

.form .form-item .form-label input:focus ~ label,
.form .form-item .form-label input.hascontent ~ label,
.form .form-item .form-label textarea:focus ~ label,
.form .form-item .form-label textarea.hascontent ~ label {
  font-size: 14px; }

.form .form-item .checkbox-label {
  font-size: 14px; }

h3 {
  font-size: 16px; }

footer {
  font-size: 14px; }
  footer .small {
    font-size: 12px; }
    footer .small span {
      font-size: 12px;
      font-weight: 500; }

.impressum .title, .datenschutz .title {
  color: #6B6B6B;
  margin-bottom: 50px;
  /* Mobile */
  /* Mobile */ }
  @media (max-width: 480px) {
    .impressum .title, .datenschutz .title {
      font-size: 30px; } }
  @media (min-width: 480px) {
    .impressum .title, .datenschutz .title {
      font-size: 30px; } }

.impressum .wrapper ul, .impressum .wrapper--medium-width ul, .impressum .wrapper--small-width ul, .datenschutz .wrapper ul, .datenschutz .wrapper--medium-width ul, .datenschutz .wrapper--small-width ul {
  color: #6B6B6B;
  margin-left: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 300; }

/* General */
#home {
  z-index: 150; }

.sticky {
  position: fixed;
  top: 0;
  width: 100%; }
  @media (max-width: 480px) {
    .sticky .navigation {
      margin-top: 150px; } }
  @media (min-width: 480px) {
    .sticky .navigation {
      margin-top: 150px; } }
  @media (min-width: 667px) {
    .sticky .navigation {
      margin-top: 0px; } }

.nav {
  display: flex;
  padding: 20px 0; }
  .nav .nav-logo {
    display: block;
    z-index: 150; }
    .nav .nav-logo img {
      width: 150px; }
  .nav .nav-menu {
    display: block;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -ms-flex-pack: flex-end; }
    .nav .nav-menu .navigation {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex; }
      @media (max-width: 480px) {
        .nav .nav-menu .navigation {
          display: none;
          position: absolute;
          left: 26%;
          width: 50%;
          top: 2.2%;
          text-align: center;
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column; }
          .nav .nav-menu .navigation ul {
            height: 50vh;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            -moz-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between; }
            .nav .nav-menu .navigation ul li a {
              font-size: 26px;
              font-weight: 700; } }
      @media (min-width: 480px) {
        .nav .nav-menu .navigation {
          display: none;
          position: absolute;
          left: 26%;
          width: 50%;
          top: 2.2%;
          text-align: center;
          -webkit-flex-direction: column;
          -moz-flex-direction: column;
          -ms-flex-direction: column;
          flex-direction: column; }
          .nav .nav-menu .navigation ul {
            height: 50vh;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: column;
            -moz-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-justify-content: space-between;
            -moz-justify-content: space-between;
            -ms-justify-content: space-between;
            justify-content: space-between;
            -ms-flex-pack: space-between; }
            .nav .nav-menu .navigation ul li a {
              font-size: 26px;
              font-weight: 700; } }
      @media (min-width: 667px) {
        .nav .nav-menu .navigation {
          display: inline-block;
          position: relative;
          -webkit-flex-direction: row;
          -moz-flex-direction: row;
          -ms-flex-direction: row;
          flex-direction: row;
          text-align: left;
          width: 100%;
          top: 0;
          left: 0; }
          .nav .nav-menu .navigation ul {
            -webkit-flex-direction: row;
            -moz-flex-direction: row;
            -ms-flex-direction: row;
            flex-direction: row;
            width: 100%;
            height: unset;
            -webkit-justify-content: flex-end;
            -moz-justify-content: flex-end;
            -ms-justify-content: flex-end;
            justify-content: flex-end;
            -ms-flex-pack: flex-end; }
            .nav .nav-menu .navigation ul li a {
              font-size: 16px;
              font-weight: 400; } }
      .nav .nav-menu .navigation li {
        list-style: none; }
      .nav .nav-menu .navigation li:last-child {
        margin-right: 0; }
    .nav .nav-menu .open {
      display: block;
      z-index: 200; }
    .nav .nav-menu a {
      color: #ffffff;
      text-decoration: none;
      position: relative; }
      .nav .nav-menu a:hover, .nav .nav-menu a.active {
        width: 100%;
        font-weight: 700 !important; }
        .nav .nav-menu a:hover:after, .nav .nav-menu a.active:after {
          content: "";
          display: inline-block;
          position: absolute;
          border-bottom: 2px solid #ffffff;
          width: 100%;
          right: 0;
          left: 0;
          top: 25px; }
    .nav .nav-menu input {
      display: none; }
    .nav .nav-menu .burger-menu {
      position: relative;
      border-radius: 4px;
      background: transparent;
      height: 100%;
      width: 75px;
      z-index: 150; }
      @media (max-width: 480px) {
        .nav .nav-menu .burger-menu {
          display: block; } }
      @media (min-width: 480px) {
        .nav .nav-menu .burger-menu {
          display: block; } }
      @media (min-width: 667px) {
        .nav .nav-menu .burger-menu {
          display: none; } }
    .nav .nav-menu .line {
      position: absolute;
      left: 30px;
      height: 3px;
      width: 35px;
      background: #fff;
      border-radius: 2px;
      display: block;
      transition: 0.5s;
      transform-origin: center; }
      .nav .nav-menu .line:nth-child(1) {
        top: 17px; }
      .nav .nav-menu .line:nth-child(2) {
        top: 29px;
        width: 25px;
        left: 40px; }
      .nav .nav-menu .line:nth-child(3) {
        top: 41px;
        width: 30px;
        left: 35px; }
    .nav .nav-menu #burger-menu:checked + .burger-menu .line:nth-child(1) {
      transform: translateY(12px) rotate(-45deg); }
    .nav .nav-menu #burger-menu:checked + .burger-menu .line:nth-child(2) {
      display: none; }
    .nav .nav-menu #burger-menu:checked + .burger-menu .line:nth-child(3) {
      transform: translateY(-12px) rotate(45deg);
      width: 35px;
      left: 30px; }

/* Desktop */
@media (min-width: 667px) {
  .nav {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    height: 100px; }
    .nav .nav-logo {
      -webkit-flex-grow: 1;
      -moz-flex-grow: 1;
      -ms-flex-grow: 1;
      flex-grow: 1; }
    .nav .nav-menu {
      -webkit-flex-grow: 2;
      -moz-flex-grow: 2;
      -ms-flex-grow: 2;
      flex-grow: 2; }
      .nav .nav-menu ul {
        -webkit-justify-content: flex-end;
        -moz-justify-content: flex-end;
        -ms-justify-content: flex-end;
        justify-content: flex-end;
        -ms-flex-pack: flex-end; }
        .nav .nav-menu ul li {
          margin-right: 30px; }
        .nav .nav-menu ul li:last-child {
          margin-right: 0; } }

/* Impressum & Datenschutz */
@media (max-width: 480px) {
  .impressum .nav .nav-menu .navigation {
    top: 6.5%; } }

@media (min-width: 480px) {
  .impressum .nav .nav-menu .navigation {
    top: 6.5%; } }

.impressum .nav .nav-menu .navigation ul {
  margin-left: 0; }

@media (max-width: 480px) {
  .datenschutz .nav .nav-menu .navigation {
    top: 3%; } }

@media (min-width: 480px) {
  .datenschutz .nav .nav-menu .navigation {
    top: 3%; } }

.datenschutz .nav .nav-menu .navigation ul {
  margin-left: 0; }

.flashMessage {
  background: #ffffff;
  color: black;
  padding: 20px;
  text-align: center;
  transition: all 0.5s;
  display: none;
  position: relative;
  cursor: pointer; }
  .flashMessage span {
    color: black;
    position: absolute;
    right: 20px;
    top: 35%;
    bottom: 35%; }

.btn, .btn-load {
  background: #414141;
  border: 0;
  color: #ffffff;
  width: 100%;
  text-transform: uppercase;
  padding: 15px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 20px rgba(41, 41, 41, 0.6);
  -moz-box-shadow: 0px 5px 20px rgba(41, 41, 41, 0.6);
  box-shadow: 0px 5px 20px rgba(41, 41, 41, 0.6);
  cursor: pointer; }
  .btn:focus, .btn-load:focus {
    outline: none; }

.btn-load {
  background: #4B4B4B;
  width: 200px; }

.filter-btn {
  position: relative;
  background: transparent;
  border: 0;
  border-left: 1.7px solid transparent;
  border-right: 1.7px solid transparent; }
  @media (max-width: 480px) {
    .filter-btn {
      padding: 0 5px; } }
  @media (min-width: 480px) {
    .filter-btn {
      padding: 0 5px; } }
  @media (min-width: 667px) {
    .filter-btn {
      padding: 0 15px; } }
  .filter-btn:hover {
    color: #ee2c3d;
    border-left: 0;
    border-right: 0;
    cursor: pointer; }
    .filter-btn:hover:after {
      content: "";
      display: inline-block;
      position: absolute;
      border-bottom: 2px solid #ee2c3d;
      width: 100%;
      right: 0;
      left: 0;
      top: 25px; }
  .filter-btn:focus {
    outline: none; }
  .filter-btn.active {
    color: #ee2c3d;
    border-left: 0;
    border-right: 0;
    cursor: pointer;
    font-weight: 700; }
    .filter-btn.active:after {
      content: "";
      display: inline-block;
      position: absolute;
      border-bottom: 2px solid #ee2c3d;
      width: 100%;
      right: 0;
      left: 0;
      top: 25px; }

.wrapper, .wrapper--medium-width, .wrapper--small-width {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media (max-width: 480px) {
    .wrapper, .wrapper--medium-width, .wrapper--small-width {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (min-width: 480px) {
    .wrapper, .wrapper--medium-width, .wrapper--small-width {
      padding-left: 15px;
      padding-right: 15px; } }
  @media (min-width: 1024px) {
    .wrapper, .wrapper--medium-width, .wrapper--small-width {
      padding-left: 0;
      padding-right: 0; } }
  .wrapper .row, .wrapper--medium-width .row, .wrapper--small-width .row {
    width: 100%; }
  .wrapper--no-pad {
    padding-top: 0;
    padding-bottom: 0; }
  @media (min-width: 667px) {
    .wrapper--medium-width .row {
      width: 100%; } }
  @media (min-width: 1024px) {
    .wrapper--medium-width .row {
      width: 900px;
      margin: 0 auto; } }
  @media (min-width: 1280px) {
    .wrapper--medium-width .row {
      width: 1200px;
      margin: 0 auto; } }
  @media (min-width: 667px) {
    .wrapper--small-width .row {
      width: 100%; } }
  @media (min-width: 1024px) {
    .wrapper--small-width .row {
      width: 900px;
      margin: 0 auto; } }
  .wrapper--color {
    background: #ee2c3d;
    color: #ffffff; }
    .wrapper--color--2 {
      background: #2e2e2e; }
  .wrapper--color-trans {
    background: #ee2c3d;
    /* Old browsers */
    background: -moz-linear-gradient(top, #ee2c3d 0%, #ef505e 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ee2c3d), color-stop(100%, #ef505e));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ee2c3d 0%, #ef505e 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ee2c3d 0%, #ef505e 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ee2c3d 0%, #ef505e 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #ee2c3d 0%, #ef505e 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#000000',GradientType=0 );
    /* IE6-9 */ }
  .wrapper--entry {
    max-height: 900px; }

.entry {
  /* General */
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: calc(100vh - 100px);
  overflow: hidden;
  /* Mobile */
  /* Desktop */ }
  @media (max-width: 480px) {
    .entry {
      padding-top: 120px; } }
  @media (min-width: 480px) {
    .entry {
      padding-top: 120px; } }
  @media (min-width: 667px) {
    .entry {
      padding-top: 180px; } }

.gm-iframe iframe {
  margin-bottom: -5px; }

.two-col {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  /* Mobile */
  /* Mobile */
  /* Desktop */ }
  @media (max-width: 480px) {
    .two-col {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; } }
  @media (min-width: 480px) {
    .two-col {
      -webkit-flex-direction: column;
      -moz-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column; } }
  @media (min-width: 667px) {
    .two-col {
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; } }
  .two-col .col-left {
    /* Mobile */
    /* Mobile */
    /* Desktop */ }
    .two-col .col-left img {
      max-width: 100%; }
    @media (max-width: 480px) {
      .two-col .col-left {
        width: 100%; } }
    @media (min-width: 480px) {
      .two-col .col-left {
        width: 100%; } }
    @media (min-width: 667px) {
      .two-col .col-left {
        width: 60%;
        padding-right: 30px; } }
  .two-col .col-right, .two-col .col-right--entry {
    /* Desktop */ }
    @media (min-width: 667px) {
      .two-col .col-right, .two-col .col-right--entry {
        width: 40%; } }
    .two-col .col-right--entry {
      position: relative;
      /* Mobile */
      /* Mobile */
      /* Tablet */
      /* Desktop */ }
      .two-col .col-right--entry:before {
        content: "";
        border-left: 2px solid #ffffff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -5px;
        height: 100vh;
        margin-top: 4px; }
      @media (max-width: 480px) {
        .two-col .col-right--entry {
          width: 70%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 80px;
          padding-left: 30px; } }
      @media (min-width: 480px) {
        .two-col .col-right--entry {
          width: 70%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 80px;
          padding-left: 30px; } }
      @media (min-width: 667px) {
        .two-col .col-right--entry {
          margin-top: 100px; } }
      @media (min-width: 1024px) {
        .two-col .col-right--entry {
          width: 40%;
          margin-top: 180px; } }
      @media (min-width: 1280px) {
        .two-col .col-right--entry {
          margin-top: 250px; } }

#entry {
  /* Mobile */
  /* Desktop */ }
  @media (max-width: 480px) {
    #entry {
      margin-top: -1px; } }
  @media (min-width: 480px) {
    #entry {
      margin-top: -1px; } }
  @media (min-width: 667px) {
    #entry {
      margin-top: 0; } }

.section-title {
  margin-bottom: 20px; }

/* Sections */
.sec-intro-text .section-title {
  margin-bottom: 30px;
  text-align: center; }

/* Services */
/* Gallery */
.sec-gallery {
  padding-left: 0;
  padding-right: 0; }
  .sec-gallery .section-title {
    text-align: center;
    margin-bottom: 20px; }
  .sec-gallery .title {
    text-align: center; }
  .sec-gallery .button-group {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 20px 0 40px 0;
    width: 100%; }
  .sec-gallery .load-more-items {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center; }

/* Contact */
.sec-contact .two-col .col-right, .sec-contact .two-col .col-right--entry {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  padding-top: 30px;
  /* Mobile */
  /* Dekstop */ }
  @media (max-width: 480px) {
    .sec-contact .two-col .col-right, .sec-contact .two-col .col-right--entry {
      margin-top: 40px; }
      .sec-contact .two-col .col-right .contact-info, .sec-contact .two-col .col-right--entry .contact-info {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        margin-bottom: 0; } }
  @media (min-width: 480px) {
    .sec-contact .two-col .col-right, .sec-contact .two-col .col-right--entry {
      margin-top: 40px; }
      .sec-contact .two-col .col-right .contact-info, .sec-contact .two-col .col-right--entry .contact-info {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: space-between;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        margin-bottom: 0; } }
  @media (min-width: 667px) {
    .sec-contact .two-col .col-right, .sec-contact .two-col .col-right--entry {
      padding-left: 100px; }
      .sec-contact .two-col .col-right .contact-info, .sec-contact .two-col .col-right--entry .contact-info {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-bottom: 30px; } }
  @media (min-width: 1280px) {
    .sec-contact .two-col .col-right, .sec-contact .two-col .col-right--entry {
      padding-left: 200px; } }
  .sec-contact .two-col .col-right > h3, .sec-contact .two-col .col-right--entry > h3, .sec-contact .two-col .col-right > div, .sec-contact .two-col .col-right--entry > div, .sec-contact .two-col .col-right > div:nth-child(2) div, .sec-contact .two-col .col-right--entry > div:nth-child(2) div {
    margin-bottom: 30px; }
    .sec-contact .two-col .col-right > h3 h3, .sec-contact .two-col .col-right--entry > h3 h3, .sec-contact .two-col .col-right > div h3, .sec-contact .two-col .col-right--entry > div h3, .sec-contact .two-col .col-right > div:nth-child(2) div h3, .sec-contact .two-col .col-right--entry > div:nth-child(2) div h3 {
      margin-bottom: 5px; }
  .sec-contact .two-col .col-right > div:nth-child(2) div:last-child, .sec-contact .two-col .col-right--entry > div:nth-child(2) div:last-child {
    /* Mobile */ }
    @media (max-width: 480px) {
      .sec-contact .two-col .col-right > div:nth-child(2) div:last-child, .sec-contact .two-col .col-right--entry > div:nth-child(2) div:last-child {
        margin-bottom: 30px; } }
    @media (min-width: 480px) {
      .sec-contact .two-col .col-right > div:nth-child(2) div:last-child, .sec-contact .two-col .col-right--entry > div:nth-child(2) div:last-child {
        margin-bottom: 30px; } }
    @media (min-width: 667px) {
      .sec-contact .two-col .col-right > div:nth-child(2) div:last-child, .sec-contact .two-col .col-right--entry > div:nth-child(2) div:last-child {
        margin-bottom: 0px; } }
  .sec-contact .two-col .col-right > div:nth-last-child(1), .sec-contact .two-col .col-right--entry > div:nth-last-child(1) {
    margin-bottom: 0; }
  .sec-contact .two-col .col-right .opening-hours div, .sec-contact .two-col .col-right--entry .opening-hours div {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 2px;
    /* Mobile */ }
    @media (max-width: 480px) {
      .sec-contact .two-col .col-right .opening-hours div, .sec-contact .two-col .col-right--entry .opening-hours div {
        width: 55%; } }
    @media (min-width: 480px) {
      .sec-contact .two-col .col-right .opening-hours div, .sec-contact .two-col .col-right--entry .opening-hours div {
        width: 55%; } }
    @media (min-width: 667px) {
      .sec-contact .two-col .col-right .opening-hours div, .sec-contact .two-col .col-right--entry .opening-hours div {
        width: 100%; } }

/* Google Maps */
.sec-map {
  padding-left: 0;
  padding-right: 0; }

.services {
  list-style: none; }
  .services .services-item {
    border-bottom: 2px solid #ffffff;
    padding: 25px 0 15px 0;
    overflow: hidden; }
    .services .services-item:first-child {
      padding-top: 0; }
    .services .services-item:nth-last-child(1) {
      border-bottom: 0; }
    .services .services-item .title {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: space-between;
      -moz-justify-content: space-between;
      -ms-justify-content: space-between;
      justify-content: space-between;
      -ms-flex-pack: space-between;
      -webkit-align-items: center;
      -moz-align-items: center;
      -ms-align-items: center;
      align-items: center; }
      .services .services-item .title .services-toggle {
        font-family: "Roboto", sans-serif;
        font-weight: 100;
        transition: all 0.5s;
        transform: rotate(0); }
        .services .services-item .title .services-toggle:hover {
          cursor: pointer; }
        .services .services-item .title .services-toggle.open {
          transform: rotate(45deg); }
    .services .services-item .services-item-details {
      margin-left: 20px;
      padding: 10px 0;
      font-weight: 100;
      list-style: disc;
      max-height: 0;
      transition: all 0.5s;
      opacity: 0; }
      .services .services-item .services-item-details.open {
        max-height: 100vh;
        opacity: 1; }
      .services .services-item .services-item-details li {
        margin: 7px 0;
        padding-left: 10px; }

/* zoom overwrite */
.zoom-overlay {
  background: rgba(0, 0, 0, 0.7); }

.zoom-img {
  width: 100% !important;
  min-width: 100% !important;
  border: 5px solid #ffffff; }

/* Isotope */
.grid {
  width: 100%;
  padding: 8px !important; }
  .grid .grid-item {
    float: left;
    height: 242px;
    border: 0;
    background: transparent;
    margin: 15px;
    visibility: visible; }
    .grid .grid-item img {
      width: 100%;
      object-fit: cover;
      height: 100%; }
  .grid .filter-invisible {
    visibility: hidden; }
  @media (max-width: 480px) {
    .grid .grid-item {
      width: 100%; } }
  @media (min-width: 480px) {
    .grid .grid-item {
      width: 100%; } }
  @media (min-width: 667px) {
    .grid .grid-item {
      width: 50%; } }
  @media (min-width: 1024px) {
    .grid .grid-item {
      width: 33.33333%; } }
  .grid .item-visible {
    display: block !important; }
  .grid .item-invisible {
    display: none !important; }

.form {
  padding-top: 50px; }
  .form .form-item .form-label {
    position: relative;
    display: block;
    margin: 0 auto; }
    .form .form-item .form-label label {
      -webkit-transform: translateY(50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(50%);
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      position: absolute;
      top: 10%;
      left: 1em;
      background: none;
      cursor: text;
      pointer-events: none;
      font-family: sans-serif; }
    .form .form-item .form-label .textarea-label {
      top: 0; }
    .form .form-item .form-label input, .form .form-item .form-label textarea {
      display: block;
      -webkit-transition: border-color;
      -moz-transition: border-color;
      transition: border-color;
      border: 0;
      border-bottom: 4px solid #ffffff;
      width: 100%;
      background: transparent;
      margin-top: 20px;
      color: #ffffff;
      padding-left: 20px; }
    .form .form-item .form-label input {
      height: 60px;
      margin-bottom: 50px; }
    .form .form-item .form-label textarea {
      min-height: 100px;
      max-height: 500px;
      max-width: 100%;
      min-width: 100%;
      margin-top: 30px; }
    .form .form-item .form-label input:focus,
    .form .form-item .form-label textarea:focus {
      outline: none; }
    .form .form-item .form-label input:focus ~ label,
    .form .form-item .form-label input.hascontent ~ label {
      top: -30%;
      left: 0; }
    .form .form-item .form-label textarea:focus ~ label,
    .form .form-item .form-label textarea.hascontent ~ label {
      top: -35px;
      left: 0; }
  .form .form-item input[type="checkbox"] {
    display: none; }
  .form .form-item input[type="checkbox"] + label::before {
    width: 20px;
    height: 20px;
    background-color: #fff;
    display: block;
    content: "";
    float: left;
    margin-right: 20px; }
  .form .form-item input[type="checkbox"]:checked + label::before {
    box-shadow: inset 0px 0px 0px 3px #fff;
    background-color: #ee2c3d; }
  .form .form-item .checkbox-label p {
    margin-left: 40px;
    font-size: 14px;
    margin-top: -4px;
    line-height: 20px; }
  .form .form-checkbox {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    margin-top: 20px;
    margin-bottom: 40px; }
    .form .form-checkbox input {
      margin: 0 10px 10px 0; }

footer {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 30px 0;
  /* Mobile */ }
  @media (max-width: 480px) {
    footer {
      -webkit-flex-direction: column-reverse;
      -moz-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; } }
  @media (min-width: 480px) {
    footer {
      -webkit-flex-direction: column-reverse;
      -moz-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; } }
  @media (min-width: 667px) {
    footer {
      -webkit-flex-direction: row;
      -moz-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; } }
  footer .footer-copyright {
    /* Mobile */ }
    @media (max-width: 480px) {
      footer .footer-copyright {
        text-align: center; } }
    @media (min-width: 480px) {
      footer .footer-copyright {
        text-align: center; } }
    @media (min-width: 667px) {
      footer .footer-copyright {
        text-align: left; } }
  footer .footer-links ul {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    /* Mobile */ }
    footer .footer-links ul li {
      list-style: none;
      margin-right: 30px; }
      footer .footer-links ul li:last-child {
        margin-right: 0; }
    @media (max-width: 480px) {
      footer .footer-links ul {
        margin-bottom: 30px; } }
    @media (min-width: 480px) {
      footer .footer-links ul {
        margin-bottom: 30px; } }
    @media (min-width: 667px) {
      footer .footer-links ul {
        margin-bottom: 0; } }

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  font-family: "Roboto", sans-serif;
  color: #2e2e2e; }

.container {
  position: relative; }

.menu-overlay.active {
  background: #ee2c3d;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99; }
